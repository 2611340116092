import {useSearchState} from "../SearchContext";
import React, {useCallback, useState} from "react";
import {dateToEndOfDay, dateToStartOfDay} from "../../utility";
import Box from "@mui/material/Box";
import {AccordionFilter} from "../AccordionFilter";
import {FilterDate} from "./FilterDate";
import useDeepCompareEffect from "use-deep-compare-effect";

/**
 * Given a column name, returns the corresponding property name.
 *
 * @param {string} columnName One of "produced_at", "created_at", or "updated_at".
 * @returns {string} The corresponding property name.
 */
function getPropertyNameFromColumnName(columnName) {
    switch (columnName) {
        case "produced_at":
            return "productionDate";
        case "created_at":
            return "createdAt";
        case "updated_at":
            return "updatedAt";
        default:
            return "createdAt";
    }
}

/**
 * A date range filter component.
 *
 * This component is used to filter the dataset by a date range. The component
 * is an accordion that can be expanded to show a date range picker.
 *
 * @param {{columnName: string, accordionTitle: string, callback: function, outerLimits: object}} props
 * @param {string} props.columnName The name of the column to filter on.
 * Must be one of "produced_at", "created_at", or "updated_at".
 * @param {string} props.accordionTitle The title of the accordion.
 * @param {function} props.callback The callback function to call when the filter
 * is changed.
 *
 * @returns {JSX.Element} The component.
 */
export const AccordionFilterDateNew = ({
                                           columnName,
                                           accordionTitle,
                                           callback,
                                       }) => {

    const {results, fq, clearFilters} = useSearchState();

    const [loading, setLoading] = useState(true);
    const [minMaxDate, setMinMaxDates] = useState();
    const [selected, setSelected] = useState([]);

    const key = columnName;
    const alreadyFiltered = fq.findIndex((f) => f.key === key) !== -1;

    const storageKey = 'dams.filter.minMaxDate["' + columnName + '"]';

    /**
     * Modify the selected filter state.
     *
     * If the filter is not already present in the selected filter state, add it.
     * If the filter is already present, update its value.
     *
     * If the filter was already present in the previous search, and the new value
     * is *, replace the value with the value from the previous search.
     *
     * @param {object} value The new value of the filter. Must contain 'from' and 'to' properties.
     * @returns {void}
     */
    const modifySelectedFilter = (value) => {
        let selectedFilters = selected;
        let fk = key === "produced_at" ? "production_date" : key;
        let ix = selectedFilters.findIndex((f) => f.key === fk);

        if (alreadyFiltered) {
            // Attempt to get filter value from previous search.
            ix = fq.findIndex((f) => f.key === fk);
        }

        if (ix === -1) {
            selectedFilters.push({key: fk, value: value});
        } else {
            if (alreadyFiltered) {
                const fqValues = getFqValues();
                value.to = value.to === "*" ? fqValues.to : value.to;
                value.from = value.from === "*" ? fqValues.from : value.from;
            }
            selectedFilters[ix] = {key: fk, value: value};
        }
        callback(fk, selectedFilters);
    };

    const getFqValues = useCallback(() => {
        let fqIx = fq.findIndex((f) => f.key === key);
        let values = fq[fqIx].value;
        values = values.substr(1, values.length - 2).split(" TO ");
        return {from: values[0], to: values[1]};
    }, [fq, key]);

    /**
     * Save the min- and max date range to localStorage.
     *
     * This function is used to save the min- and max date range to localStorage.
     * The date range is stored in a JSON object with the following structure:
     * {
     *     min: <Date>,
     *     max: <Date>
     * }
     *
     * @param {{min: Date, max: Date}} dateRange The date range to save.
     * @returns {void}
     */
    const saveMinMax = (dateRange) => {
        if (clearFilters) {
            return;
        }
        localStorage.setItem(storageKey, JSON.stringify(dateRange));
    };

    /**
     * Modifies the selected filter value, according to the changes made by the user.
     *
     * @param {{from: string, to: string}} value - The new filter value as specified by the user.
     * @returns {void}
     */
    const changeHandler = ({from = "*", to = "*"}) => {
        modifySelectedFilter({from: from, to: to});
    };

    /**
     * Gets the min- and max date range from the filter settings.
     *
     * This function is used to extract the min- and max date range from the filter settings.
     * The date range is returned as an object with the following structure:
     * {
     *     min: <number>,
     *     max: <number>
     * }
     * Where the `min` and `max` properties are the timestamps of the min and max dates, respectively.
     *
     * @returns {{min: number, max: number}} The min- and max date range from the filter settings.
     */
    const getMinMaxFromFilterSettings = () => {
        // Get the min- and max date range from the filter settings.
        const fqValues = getFqValues();
        return {
            min: new Date(fqValues.from).getTime(),
            max: new Date(fqValues.to).getTime()
        };
    };


    /**
     * Hook used to extract the min- and max date range from the results/stats data or from the filter settings.
     */
    useDeepCompareEffect(() => {

        /**
         * Resets the filter value and saves the min- and max date range to localStorage.
         *
         * This function is used to reset the filter value and save the min- and max date range
         * to localStorage. The function is called when the component is mounted and when the
         * user clicks the "Reset" button.
         *
         * If the `clearFilters` prop is set to `true`, the function will reset the filter value
         * and fetch the min- and max date range from localStorage. If the `alreadyFiltered`
         * prop is set to `true`, the function will fetch the min- and max date range from the
         * filter settings. Otherwise, the function will fetch the min- and max date range from
         * the results/stats data.
         *
         * @returns {void}
         */
        const setDateRange = () => {
            let dateRange = {};

            if (clearFilters && results) {
                // Reset the filter value, by fetching the min- and max date range from localStorage.
                const savedDateRange = localStorage.getItem(storageKey);
                if (savedDateRange && savedDateRange !== '') {
                    setMinMaxDates(JSON.parse(savedDateRange));
                }
                setSelected([]);
                callback(key, []);
            } else if (results && !alreadyFiltered && !clearFilters) {
                const statsDate = results.stats[getPropertyNameFromColumnName(columnName)];
                dateRange = {
                    min: dateToStartOfDay(new Date(statsDate.min)),
                    max: dateToEndOfDay(new Date(statsDate.max))
                };
                // Save the values fetched from the dataset, as these will be used when resetting the filter.
                saveMinMax(dateRange);
            } else if (alreadyFiltered && !clearFilters) {
                dateRange = getMinMaxFromFilterSettings();
            }

            if (Object.keys(dateRange).length > 0) {
                setMinMaxDates(dateRange);
                setLoading(false);
            }
        };

        if (!results) {
            return;
        }

        setLoading(true);
        setDateRange();
    }, [results]);

    return !loading && <Box sx={{marginBottom: '.5rem'}}>
        <AccordionFilter title={accordionTitle}>
            <FilterDate
                min={minMaxDate?.min}
                max={minMaxDate?.max}
                onFilter={changeHandler}
            />
        </AccordionFilter>
    </Box>;
};