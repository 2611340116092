import React, {useEffect, useState} from "react";
import {useFolderTranslation} from "./MyFoldersContext";
import {Formik} from "formik";
import * as Yup from "yup";
import {FormikTextField} from "../form/FormikTextField";
import {FormikRadioGroup} from "../form/FormikRadioGroup";
import {ToolbarCreateFolder} from "./ToolbarCreateFolder";
import {useMyMuseums} from "../museum/useMyMuseums";
import {If} from "../app/If";
import {FormikMuseumToCollectionSelect} from "../form/FormikMuseumToCollectionSelect";
import {FormikEmailAcl} from "../form/FormikEmailAcl";
import {DatePickerProvider} from "../form/DatePickerContext";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import {InputTags} from "./tags/InputTags";

const PREFIX = "FormCreateFolder";

const classes = {
    marginBottom: `${PREFIX}-marginBottom`,
};

const StyledDatePickerProvider = styled(DatePickerProvider)(({theme}) => ({
    [`& .${classes.marginBottom}`]: {
        marginBottom: theme.spacing(2),
    },
}));

/**
 * A Yup schema for a folder. Requires a folderName, which is a string, and a collectionId, which is a positive integer.
 * @param {Function} t - A function that translates strings.
 * @returns {Object} A Yup schema.
 */
const schema = (t) => {
    return Yup.object().shape({
        folderName: Yup.string().required(t("requiredFeedback", "Påkrevd")),
        collectionId: Yup.number()
            .integer()
            .positive()
            .required(t("requiredFeedback", "Påkrevd")),
    });
};


/**
 * A form for creating a new folder.
 *
 * @param {Function} onSave - A function that will be called when the form is submitted.
 * @param {Function} onHide - A function that will be called when the form should be hidden.
 * @param {number} collectionId - The id of the collection in which the folder will be created.
 *
 * @returns {React.ReactElement} A form for creating a new folder.
 */
export const FormCreateFolder = ({onSave, onHide, collectionId = 0}) => {
    const [museums, setMuseums] = useState([]);

    const t = useFolderTranslation();

    const myMuseums = useMyMuseums({});

    useEffect(() => {
        if (!myMuseums || myMuseums?.length === 0) {
            return;
        }
        setMuseums(myMuseums);
    }, [myMuseums]);

    return <StyledDatePickerProvider>
        <Formik
            initialValues={{
                folderName: "",
                folderAvailability: "private",
                collectionId: collectionId,
                museum: "",
                tags: [],
                readShare: [],
                writeShare: [],
            }}
            onSubmit={onSave}
            validationSchema={schema(t)}
        >
            <Box>
                <If boolean={0 === collectionId}>
                    <FormikMuseumToCollectionSelect
                        formikKey={"museum"}
                        label={t("createInMuseum", "Opprett mappen i")}
                        required={true}
                        sx={{marginBottom: 2}}
                        fullWidth={true}
                    >
                        {museums.map((museum) => (
                            <MenuItem key={museum.id} value={museum.id}>
                                {museum.name}
                            </MenuItem>
                        ))}
                    </FormikMuseumToCollectionSelect>
                </If>
                <FormikTextField
                    formikKey={"folderName"}
                    label={t("folderName", "Mappenavn")}
                    required={true}
                    className={classes.marginBottom}
                    fullWidth={true}
                />
                <InputTags formikKey={"tags"} collectionId={collectionId}/>
                <Box sx={{pt: 4, pb: 2}}>
                    <FormikRadioGroup
                        formikKey={"folderAvailability"}
                        required={true}
                        className={classes.marginBottom}
                        label={t("availability", "Tilgjengelighet")}
                    >
                        <FormControlLabel
                            value="private"
                            control={<Radio/>}
                            label={t("private", "Privat")}
                        />
                        <FormControlLabel
                            value="public"
                            control={<Radio/>}
                            label={t("public", "Offentlig")}
                        />
                    </FormikRadioGroup>
                </Box>
                <FormikEmailAcl
                    formikKey={"readShare"}
                    required={false}
                    className={classes.marginBottom}
                    label={t("readShare", "Gi lesetilgang")}
                />
                <FormikEmailAcl
                    formikKey={"writeShare"}
                    required={false}
                    className={classes.marginBottom}
                    label={t("writeShare", "Gi skrivetilgang")}
                />
                <ToolbarCreateFolder onHide={onHide}/>
            </Box>
        </Formik>
    </StyledDatePickerProvider>;
};
