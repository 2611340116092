import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {SearchResults} from "./SearchResults";
import {SearchResultDetailsProvider} from "../search-result-details/SearchResultDetailsContext";
import {DocumentTypeProvider} from "../document-type/documentTypeContext";
import {
    SET_QUERY,
    SET_ROWS,
    SET_SEARCHING,
    SET_START,
    SHOW_INSTANTLY,
    useSearchDispatch,
    useSearchState,
    useSearchTranslation,
} from "./SearchContext";
import {MuseumSelector} from "../museum/MuseumSelector";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {SearchSettings} from "./SearchSettings";
import {SearchInput} from "./SearchInput";


const PREFIX = "SearchLandingPage";

const classes = {
    landingPage: `${PREFIX}-landingPage`,
    header: `${PREFIX}-header`,
    search: `${PREFIX}-search`,
    museumSelector: `${PREFIX}-museumSelector`,
    searchAndType: `${PREFIX}-searchAndType`,
    searchInput: `${PREFIX}-searchInput`,
};

const Root = styled("div")(({theme}) => ({
    [`&.${classes.landingPage}`]: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flexGrow: 1,
        alignItems: 'stretch',
        justifyContent: 'stretch',
    },

    [`& .${classes.search}`]: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",
        gap: '.5rem',
        margin: '.25rem',
        paddingBlock: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            flexDirection: 'row',
            margin: '.5rem',
        },
        [theme.breakpoints.up("md")]: {
            gap: '1rem',
            margin: '1rem',
        },
    },

    [`& .${classes.museumSelector}`]: {
        display: "flex",
        alignItems: "center",
    },

    [`& .${classes.searchAndType}`]: {
        [theme.breakpoints.up("xs")]: {
            flexGrow: 1,
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: '60%'
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: '50%'
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: '38rem'
        },
    },

    [`& .${classes.searchInput}`]: {},
}));

/**
 * A page component used to display the search form and results.
 *
 * @param {object} props The component's props.
 * @param {boolean} props.startSearched Whether to start searching immediately.
 * @returns {JSX.Element} The component.
 */
export const SearchLandingPage = ({startSearched = false}) => {
    const [searched, setSearched] = useState(startSearched);
    const [inputValue, setInputValue] = useState("");

    const {onlyMine, query} = useSearchState();

    const searchDispatch = useSearchDispatch();
    const t = useSearchTranslation();
    const {searchString} = useParams();

    const searchSettings = SearchSettings('dams.searchResults.config');

    /**
     * Gets the ID of a shared object from the current URL.
     *
     * The ID is extracted from the "view" parameter of the URL.
     *
     * @returns {string|null} The ID of the shared object if present, otherwise null.
     */
    const getSharedObjectId = () => {
        const params = new URLSearchParams(window.location.search);
        return params.get("view");
    };

    /**
     * Sets the search criteria to the given value and starts a new search.
     *
     * @param {string} inValue The new search value.
     * @param {string} q The query to use for the search.
     *
     * This function is used to set the search criteria to the given value and
     * start a new search. If the value is empty, it will clear the search criteria.
     *
     * This function is used by the search form and by the URL handling code when
     * the user navigates to a URL with a "view" parameter.
     */
    const setCriterias = (inValue, q) => {
        setInputValue(inValue);
        setSearched(true);
        searchDispatch({
            type: SET_QUERY,
            query: q,
            folderContext: false,
        });
        searchDispatch({
            type: SET_SEARCHING
        });
    };

    /**
     * Sets the criterias used to fetch shared objects.
     *
     * NOTE:
     * This method is called when the application is accessed by a user who has received an e-mail with shared objects
     * from a "regular" DAMS-user.
     * @param sharedObjectId
     */
    const setSharedObjectsCriterias = (sharedObjectId) => {
        const q = '"' + sharedObjectId + '"';
        searchDispatch({
            type: SET_START,
            start: 0,
        });
        searchDispatch({
            type: SHOW_INSTANTLY,
            showInstantly: sharedObjectId
        });
        setTimeout(() => {
            searchDispatch({
                type: SET_QUERY,
                query: q,
                folderContext: false,
                timestamp: new Date().getTime()
            });
            setSearched(true);
        }, 200);
    };

    /**
     * Sets the criterias used when executing a regular search.
     */
    const setRegularSearchCriterias = () => {
        if (!searchString || searchString === "*") {
            setCriterias("", "*");
        } else if (searchString) {
            setCriterias(searchString, searchString);
        }

        const rows = searchSettings.getNumberOfRows() || 10;

        searchDispatch({
            type: SET_ROWS,
            rows: rows
        });
    };

    /**
     * Executes search when the user presses the "Enter" key.
     * @param event
     * @param value
     */
    const searchOnEnter = (event, value) => {
        if ("Enter" === event.key) {
            let q = inputValue || value;
            if (q === "") {
                q = "*";
            }
            searchDispatch({
                type: SET_START,
                start: 0,
            });
            searchDispatch({
                type: SET_QUERY,
                query: q,
                folderContext: false,
                timestamp: new Date().getTime()
            });
            searchDispatch({
                type: SET_SEARCHING
            });
            setSearched(true);
        }
    };

    /**
     * Displays the museum selector / filter, when outside "my files".
     * @returns {JSX.Element}
     */
    const getMuseumSelector = () => {
        if (!onlyMine) {
            return <div className={classes.museumSelector}>
                <Typography variant={"body2"}>
                    {t("searchIn", "Søk i:")}
                </Typography>
                <MuseumSelector/>
            </div>
        }
    };

    /**
     * Hook used to execute the appropriate search.
     */
    useEffect(() => {
        const sharedObjectId = getSharedObjectId();
        if (sharedObjectId) {
            setSharedObjectsCriterias(sharedObjectId);
        } else {
            setRegularSearchCriterias();
        }
    }, [searchString, searchDispatch]);

    /**
     * Hook used to remove the search criteria from the input field
     * when the query has been reset, and initial results are loaded.
     */
    useEffect(() => {
        if (query === '*' || query === '') {
            setRegularSearchCriterias();
        }
    }, [query]);

    const searchInputLabel = onlyMine ? t("SearchMyFiles", "Søk i Mine filer") : t("Search", "Søk");

    return (
        <Root className={classes.landingPage}>
            <DocumentTypeProvider>
                <div className={classes.search}>
                    <SearchInput classes={classes} searchOnEnterCallback={searchOnEnter} label={searchInputLabel}/>
                    {getMuseumSelector()}
                </div>
                <SearchResultDetailsProvider>
                    {searched && <SearchResults/>}
                </SearchResultDetailsProvider>
            </DocumentTypeProvider>
        </Root>
    );
};
