import React, {useState} from "react";
import {KulturNavSearch} from "../kulturnav/KulturNavSearch";
import {damsSearch} from "../app/damsFetch";
import {
    concatWithExisting,
    excludeThoseAlreadyListedAsKulturnav,
    fieldValuesWithCollectionIds,
    inputWithCollectionIds,
    sourceToUniqueId,
} from "../metadata/inputMapper";
import {kulturnavApiCoreFetch} from "../app/kulturnavFetch";
import {FormNewPlace} from "./FormNewPlace";
import {useAddPlace} from "./useAddPlace";
import {ModalCreateResource} from "../metadata/ModalCreateResource";
import {ModalCreateResourceHeader} from "../metadata/ModalCreateResourceHeader";
import {useField, useFormikContext} from "formik";
import {useDocumentState, useDocumentTranslation,} from "../documents/documentContext";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {useAuthsState} from "../auths/authsContext";
import PlaceIcon from "@mui/icons-material/Place";
import {mapNotMissing} from "../inpututils";
import {apiCoreObjectToDAMSInput} from "../utility";
import {clientLog} from "../clientLog";

/**
 * Renders an input component for selecting places.
 *
 * @param {string} formikKey - The key for the Formik field.
 * @param {string} label - The label for the input.
 * @param {string} createLabel - The label for the create button.
 * @param {boolean} [disabled=false] - Whether the input is disabled.
 * @param {string} [size="large"] - The size of the input.
 * @param {string|null} [projectCollectionId=null] - The project collection ID.
 * @return {JSX.Element} The rendered input component.
 */
export const InputPlaces = ({
                                formikKey,
                                label,
                                createLabel,
                                disabled = false,
                                size = null,
                                projectCollectionId = null,
                            }) => {
    const t = useDocumentTranslation();
    const [field, meta] = useField(formikKey);
    const {setFieldValue} = useFormikContext();
    const [newPlace, setNewPlace] = useState(null);
    const postPlaces = useAddPlace();
    const {collectionIds} = useDocumentState();
    const {museumCollections} = useAuthsState();

    // NOTE: When editing data for a project, only one collectionID is selected, and is sent directly to this component.
    const currentCollectionIds = collectionIds.length === 0 ? [projectCollectionId] : collectionIds;

    const museums = museumCollections
        .filter((museumCollection) =>
            currentCollectionIds.includes(museumCollection.collectionId)
        )
        .map((museumCollection) => museumCollection.museumId);


    const kulturNavFetchData = (params) => {
        const removeDeprecatedKey = 'superconcept.status:853a2d84-5b2b-11e2-bcfd-0800200c9a66';
        const url = `entityType:Place,compoundName:${params},${removeDeprecatedKey}?properties=entity.dataset,entity.fullCaption&displayValue=true`;
        return kulturnavApiCoreFetch(url).then(json => {
            const mapped = json['entities'].map(d => {
                return apiCoreObjectToDAMSInput(d);
            });
            return Promise.resolve(mapped);
        }).catch(error => {
            clientLog('error', error, 'InputPlaces');
            return Promise.resolve([]);
        });
    };

    const placesSearchHandler = (params, callback) => {
        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: `_title_:${params}`,
                fl: "title,id",
                sort: "title asc",
                expand: true,
                documentType: `("place")`,
                museums: museums.join(","),
            })
        );

        Promise.all([
            kulturNavFetchData(params),
            damsSearch(searchParams).then((json) =>
                Promise.resolve(inputWithCollectionIds(json['models']))
            ),
        ]).then(([kulturnavResponse, damsResponse]) => {
            const damsLookup = sourceToUniqueId(damsResponse);
            const kulturnavMapped = concatWithExisting(kulturnavResponse, damsLookup);
            callback([
                ...kulturnavMapped,
                ...excludeThoseAlreadyListedAsKulturnav(damsResponse, kulturnavMapped),
            ]);
        });
    };

    const onAdd = (places) => {
        setFieldValue(field.name, [...field.value, ...places]).then();
    };

    const onChange = (places) => {
        setFieldValue(field.name, places).then();
    };

    const postMissing = (value) => {
        const places = currentCollectionIds
            .filter((collectionId) => !value.collectionId.includes(collectionId))
            .filter(() => !value.uniqueId && !value.id)
            .map((collectionId) => ({
                ...value,
                collectionId: collectionId
            }));
        return postPlaces({places: places});
    };

    const changeHandler = (values) => {
        const addingPlace = values.find(value => value.create);
        const createExternalPlace = values.find(place => !place.uniqueId);
        if (addingPlace) {
            setNewPlace(addingPlace);
        } else if (createExternalPlace) {
            createExternalPlace['content']['nameAndTitle'] = createExternalPlace.title;
            const newPlaces = currentCollectionIds.map(collectionId => ({
                ...createExternalPlace,
                collectionId: collectionId
            }));
            postPlaces({places: newPlaces}).then(onAdd);
        } else {
            const existingPlaces = values.map(v => mapNotMissing(currentCollectionIds, v, 'place')).flat();
            Promise.all(values.map(postMissing)).then((newPlaces) =>
                onChange([...existingPlaces, ...newPlaces.flat()])
            );
        }
    };

    const clearHandler = () => {
        onChange([]);
    };

    const getValue = () => {
        if (field.value) {
            return fieldValuesWithCollectionIds(field.value);
        } else {
            return [];
        }
    };

    if (undefined === meta.initialValue || !currentCollectionIds.length) {
        return null;
    }

    return (
        <>
            <KulturNavSearch
                id={"search-knav-places"}
                searchHandler={placesSearchHandler}
                onChange={changeHandler}
                onClear={clearHandler}
                disabled={disabled}
                inputLabel={label}
                Icon={PlaceIcon}
                value={getValue()}
                size={size}
                helperText={t("placesHelp", "Som omtales/er avbildet")}
            />
            <ModalCreateResource
                open={null !== newPlace}
                onHide={() => setNewPlace(null)}
            >
                <ModalCreateResourceHeader title={createLabel}/>
                <FormNewPlace
                    place={newPlace}
                    onClose={() => setNewPlace(null)}
                    addHandler={onAdd}
                    collectionIds={currentCollectionIds}
                />
            </ModalCreateResource>
        </>
    );
};
