import {DamsFileIcon} from "../../files/DamsFileIcon";
import React, {useEffect, useState} from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import {useStyles} from "./resultCardStyles";
import PropTypes from "prop-types";


/**
 * Renders a file icon for an Archeology project file.
 *
 * @param {Object} props - The component props.
 * @param {string} props.mimeType - The MIME type of the file.
 * @param {Object} props.model - The model object containing the file information.
 * @param {boolean} [props.icon=true] - Whether to display the icon.
 *
 * The icon is selected based on the file type. For "Geodata", a {@link LocationOnOutlinedIcon} is used.
 * For "Tabell", a {@link TableChartOutlinedIcon} is used. For "StillImage", a {@link PhotoCameraOutlinedIcon} is used.
 * For "Video", a {@link VideocamOutlinedIcon} is used.
 *
 * @return {JSX.Element} The rendered file icon component.
 */
const ArcheologyProjectFileIcon = ({mimeType, model, icon = true}) => {
    const classes = useStyles();

    const [docIcon, setDocIcon] = useState(undefined);
    useEffect(() => {
        if (model.documentType === "Geodata") {
            setDocIcon(
                <LocationOnOutlinedIcon sx={{fontSize: 135, color: "#bbb"}}/>
            );
        }

        if (model.documentType === "Tabell") {
            setDocIcon(
                <TableChartOutlinedIcon sx={{fontSize: 135, color: "#bbb"}}/>
            );
        }

        if (model.documentType === "StillImage") {
            setDocIcon(
                <PhotoCameraOutlinedIcon sx={{fontSize: 135, color: "#bbb"}}/>
            );
        }

        if (model.documentType === "Video") {
            setDocIcon(<VideocamOutlinedIcon sx={{fontSize: 135, color: "#bbb"}}/>);
        }


    }, [model]);

    return (
        <>
            <div className={classes.fileThumbContainer}>{docIcon}</div>
            <div className={classes.fileTypeContainer}>
                {icon &&
                    <DamsFileIcon
                        mimeType={mimeType}
                        size={36}
                        filename={model.content.mediae[0].reference.title}
                    />}
            </div>
        </>
    );
};

ArcheologyProjectFileIcon.propTypes = {
    "mimeType": PropTypes.string.isRequired,
    "model": PropTypes.object.isRequired,
    "icon": PropTypes.bool
};

export {ArcheologyProjectFileIcon};

