import {useEmail} from "./useEmail";
import {useAuthsState} from "../auths/authsContext";
import {useTranslation} from "react-i18next";
import {PromisePool} from "@supercharge/promise-pool";


/**
 * A hook that provides functions for setting the list of email recipients
 * that will receive an email when given access to the folder, and for
 * sending emails to the recipients.
 *
 * The hook returns an array of two functions. The first function is
 * `setEmailRecipients`, which takes an array of access control list (ACL)
 * items and sets the list of email recipients that will receive an email
 * when given access to the folder. The second function is `sendEmailNotifications`,
 * which sends an email to the list of email recipients set with `setEmailRecipients`.
 *
 * @returns {array} An array of two functions: `setEmailRecipients` and `sendEmailNotifications`
 */
export const useFolderShareEmail = () => {
    const {t} = useTranslation("dams.email", {useSuspense: false});
    const {userData} = useAuthsState();
    // eslint-disable-next-line no-unused-vars
    const [sendEmail, sendSingleMail] = useEmail(); // NOSONAR

    let _emailRecipients;

    /**
     * Sets the list of email recipients that will receive an email when given
     * access to the folder.
     *
     * If `existingAcls` is null, the list of email recipients is set to
     * `unsavedAcls`. Otherwise, any ACL items in `unsavedAcls` that do not
     * already exist in `existingAcls` are added to the list of email
     * recipients.
     *
     * @param {object[]} existingAcls - The existing ACLs for the folder.
     * @param {object[]} unsavedAcls - The ACLs to add to the list of email
     * recipients.
     */
    const setEmailRecipients = (existingAcls, unsavedAcls) => {
        let recipients = [];
        if (existingAcls === null) {
            recipients = unsavedAcls;
        } else {
            for (let i = 0, max = unsavedAcls.length; i < max; i++) {
                const acl = unsavedAcls[i];
                const newAcl = _getNewAcl(existingAcls, acl);
                if (newAcl !== null) {
                    recipients.push(newAcl);
                }
            }
        }
        _emailRecipients = recipients;
    };

    /**
     * Sends an email to all recipients in the `_emailRecipients` array.
     *
     * If `recipients` is not null, it will replace the `_emailRecipients` array.
     *
     * The function returns an object with two properties: `results` and `errors`.
     * The `results` property is an array of objects with the following properties:
     * - `recipient`: The email address of the recipient.
     * - `success`: A boolean indicating whether the email was sent successfully.
     * The `errors` property is an array of errors, where each error is an object
     * with the following properties:
     * - `recipient`: The email address of the recipient that caused the error.
     * - `error`: The error message.
     *
     * @param {number} folderId - The ID of the folder.
     * @param {object[]} recipients - An array of objects, where each object has the following properties:
     * - `email`: The email address of the recipient.
     * - `access`: The type of access, either "read" or "write".
     * - `expires_at`: The timestamp for when the access expires.
     * @returns {Promise<object>}
     */
    const sendEmailNotifications = async (folderId, recipients = null) => {
        if (recipients && recipients.length > 0) {
            _emailRecipients = recipients;
        }

        const {results, errors} = await PromisePool
            .withConcurrency(4)
            .for(_emailRecipients)
            .process(async (recipient) => {
                const {email, access, expires_at: expires} = recipient;
                const mailBody = _createEmailBody(folderId, access, expires.toString());
                return await sendSingleMail(t("shareFolderMailSubject", "DAMS delt mappe"), mailBody, email); // NOSONAR
            });
        _emailRecipients = [];

        const successful = results.map(r => {
            const {recipient, success} = r[0];
            return {
                recipient: recipient,
                success: success
            };
        });

        return {
            results: successful,
            errors: errors
        };
    };

    /**
     * Creates the body of an email that will be sent to the recipients when they are given access to the folder.
     *
     * The email body is translated with the `react-i18next` library, and the translated strings are loaded from the
     * `dams.email.json` file.
     *
     * @param {number} folderId - The ID of the folder.
     * @param {string} aclType - The type of access, either "read" or "write".
     * @param {string} expires - The timestamp for when the access expires.
     * @returns {string} The email body.
     */
    const _createEmailBody = (folderId, aclType, expires) => {
        return (
            t("txtFolderShareEmail1", "Hei\n\n") +
            userData.name +
            " " +
            t("txtFolderShareEmail2", "har delt en DAMS-mappe med deg") +
            ":\n\n" +
            _getShareUrl(folderId) +
            "\n\n" +
            t("txtFolderShareEmail3", "Du har") +
            " " +
            (aclType === "read"
                ? t("txtFolderShareReadAccess", "leserettigheter")
                : t("txtFolderShareWriteAccess", "skriverettigheter")) +
            " " +
            t("txtFolderShareExpiryDate", "frem til ") +
            expires +
            "\n\n" +
            t(
                "txtFolderShareEmail4",
                "For å nå denne mappen kreves en eKultur bruker"
            ) +
            ".\n\n" +
            t(
                "txtFolderShareEmail5",
                "Dersom du ikke har en eKultur bruker, må denne opprettes"
            ) +
            ":\n" +
            t(
                "txtFolderShareEmail6",
                "1. Gå til {{ekultur_url}}\n" +
                '2. Opprett ny bruker ved å klikke på "Ny her? Opprett en konto" dersom du ønsker å benytte e-mail/passord kombinasjon,' +
                " alternativt klikk på Google eller Facebook dersom du ønsker å opprette bruker via en av disse kontoene.\n" +
                "3. Når din eKultur bruker er opprettet, vil du motta en e-mail med beskjed om å verifisere din eKultur bruker.\n" +
                "4. Gå til {{dams_url}} og logg inn med din nyopprettede eKultur bruker. " +
                "Klikk på linken/adressen du mottok i denne e-mailen og du vil komme til den delte DAMS-mappen.",
                {
                    ekultur_url: window._env_.REACT_APP_LOGIN_HOST,
                    dams_url: window.location.origin,
                }
            ) +
            "\n\n" +
            t(
                "txtFolderShareEmail7",
                "Har du en eKultur bruker, trykk på linken/adressen du mottok i denne e-mailen og du vil komme direkte til den delte DAMS-mappen."
            )
        );
    };

    /**
     * Creates the URL that will be shared with the recipients when they are given access to the folder.
     *
     * The URL is created by concatenating the protocol, host, and path "/folders/{{folderId}}/".
     *
     * @param {number} folderId - The ID of the folder.
     * @returns {string} The share URL.
     */
    const _getShareUrl = (folderId) => {
        const l = window.location;
        return l.protocol + "//" + l.host + "/folders/" + folderId + "/";
    };

    /**
     * Creates a new ACL if the given ACL does not already exist in the existing ACLs.
     *
     * @param {object[]} existingAcls - The existing ACLs.
     * @param {object} acl - The ACL to check.
     * @returns {object|null} The new ACL, or null if the ACL already exists.
     */
    const _getNewAcl = (existingAcls, acl) => {
        if (!existingAcls) {
            return null;
        }
        const exists = existingAcls.find((a) => a.email === acl.email);
        return !exists ? acl : null;
    };

    return [setEmailRecipients, sendEmailNotifications];
};
