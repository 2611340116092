import React, {useCallback, useEffect, useState} from "react";
import {useSearchState, useSearchTranslation} from "../SearchContext";
import {AccordionFilter} from "../AccordionFilter";
import List from "@mui/material/List";
import {AccordionFilterTitle} from "./AccordionFilterTitle";
import {renderCheckboxes} from "./renderCheckboxes";

/**
 * Renders a list of producers with checkboxes, based on the search criteria entered by the user.
 * The component also handles the selection of filters, and updates the selected filters in the SearchContext.
 * @param {function} callback - The callback function to handle filter changes.
 * @return {JSX.Element} CheckboxFilter component with specified props.
 */
export const FilterProducer = ({callback}) => {
    const t = useSearchTranslation();
    const {fq, results, clearFilters} = useSearchState();
    const producerKey = "producer.reference.title";

    // Value entered by the user in order to search for a filter-/facet value.
    const [searchValue, setSearchValue] = useState('');

    // Holds the list of options, searched for by the user.
    const [options, setOptions] = useState({});

    // Holds the selected filter-/facet values.
    const [selected, setSelected] = useState([]);


    const changeHandler = (value) => () => {
        modifySelectedFilter(value, false);
    };

    const modifySelectedFilter = useCallback(
        (value, fromFq) => {
            let selectedFilters = selected;

            if (fromFq) {
                if (
                    !selectedFilters.find(
                        (s) => s.value === value && s.key === producerKey
                    )
                ) {
                    selectedFilters.push({key: producerKey, value: value});
                    setSelected(selectedFilters);
                }
            } else {
                if (selectedFilters.find((s) => s.value === value && s.key === producerKey)) {
                    selectedFilters = selectedFilters.filter(
                        (s) => s.value !== value && s.key === producerKey
                    );

                    setSelected(selectedFilters);
                } else {
                    selectedFilters.push({key: producerKey, value: value});
                    setSelected(selectedFilters);
                }
            }

            if (!fromFq) {
                callback(producerKey, selectedFilters);
            }
        },
        [callback, selected]
    );

    /**
     * Callback used when the user types the search criteria.
     * Used to filter the list of available filter-values/-options.
     * @param event
     */
    const searchForFilters = (event) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    /**
     * Sets the list of options, based on the search-criteria entered by the user.
     * @type {(function(*): void)|*}
     */
    const getOptions = useCallback((data) => {
        const matchingKeys = (searchValue !== '' && searchValue.length >= 2)
            ? Object.keys(data)
                .filter(k => k.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1)
            : Object.keys(data);

        // Get the subjects that matches the search criteria.
        const filteredProducers = Object.fromEntries(matchingKeys.map(k => [k, data[k]]));

        // Add selected/checked filter values to the list.
        const selectedKeys = selected.map(s => (s.value));
        const selectedProducers = Object.fromEntries(selectedKeys.map(k => [k, data[k]]));

        const opts = {...filteredProducers, ...selectedProducers};
        setOptions(opts);
    }, [searchValue, selected]);


    useEffect(() => {
        if (clearFilters && selected.length > 0) {
            setSearchValue('');
            setSelected([]);
        }
    }, [clearFilters, callback]);

    useEffect(() => {
        if (fq && !clearFilters) {
            // Add selected filters from existing query
            fq.filter((f) => f.key === producerKey).forEach((f) => {
                modifySelectedFilter(f.value.replaceAll('"', ""), true);
            });
            setSearchValue('');
        }
    }, [fq]);

    const producers = results.facets.producers;
    let count = Object.keys(producers).length;

    /**
     * Hook used to display a list of subjects based on the search-criteria entered by the user.
     */
    useEffect(() => {
        getOptions(producers);
    }, [getOptions, producers]);

    if (!count) {
        return <></>;
    } else {
        return (
            <AccordionFilter
                title={
                    <AccordionFilterTitle
                        title={`${t("producers", "Produsenter")} (${count})`}
                        value={searchValue}
                        label={t('producersSearch', 'Søk...')}
                        onChangeCallback={searchForFilters}
                        setSearchValueCallback={() => {
                            setSearchValue('');
                        }}
                    />
                }
                count={count}
                sx={{paddingInline: '1rem'}}
            >
                <List sx={{paddingInline: '1rem'}}>
                    {renderCheckboxes(producerKey, selected, options, changeHandler)}
                </List>
            </AccordionFilter>
        );
    }
};
