import React from "react";
import {useDocumentTranslation} from "./documentContext";
import {useBatchEditState} from "./batchEditContext";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

/**
 * A simple stepper that renders two steps: "Velg felter du vil redigere" and "Rediger".
 *
 * @returns {JSX.Element} A material-ui Stepper component, with two steps.
 */
export const BatchEditStepper = () => {
    const t = useDocumentTranslation();
    const {activeStepIndex} = useBatchEditState();

    /**
     * Returns an array of objects, each object representing a step in the stepper.
     * A step object should contain a "label" property with a string value.
     * The stepper will render these steps, and the active step will be selected
     * based on the value of `activeStepIndex` from the `BatchEditStateContext`.
     *
     * @returns {Array<{label: string}>}
     */
    const getSteps = () => [
        {
            label: t("chooseFields", "Velg felter du vil redigere"),
        },
        {
            label: t("edit", "Rediger"),
        },
    ];

    return (
        <Stepper
            activeStep={activeStepIndex}
            alternativeLabel={true}
            sx={(theme) => ({
                backgroundColor: theme.palette.grey[100],
                marginLeft: theme.spacing(4),
                marginRight: theme.spacing(4),
            })}
        >
            {getSteps().map((step) => {
                const labelProps = {};
                return (
                    <Step key={step.label}>
                        <StepLabel {...labelProps}>{step.label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};
