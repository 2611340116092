import {useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {useMyMuseums} from "../../museum/useMyMuseums";
import {getFolders} from "./getFolders";
import {useQuery} from "@tanstack/react-query";
import {useAuthsState} from "../../auths/authsContext";
import {ColumnFolderNameWithIcon} from "./ColumnFolderNameWithIcon";
import {getTableColumnsBase} from "../../table-base/getTableColumnsBase";
import {getMuseumNameFromCollectionId} from "../../utility";
import {getTableTranslations} from "../../table-base/getTableTranslations";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import Box from "@mui/material/Box";
import {format} from "../../app/dateFormat";

/**
 * Component rendering a table of folders, used in the "Add to folder" component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.t - The translation function.
 * @param {Function} props.setFolderCallback - The callback function to set the id of the selected folder.
 * @return {JSX.Element} The rendered component.
 */
const FoldersTable = ({t, setFolderCallback}) => {

    const {museumCollections} = useAuthsState();

    const myMuseums = useMyMuseums({});

    const [rowSelection, setRowSelection] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    });
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([{id: 'title', desc: true}]);

    const {
        data: {
            folders = [],
            meta
        } = {},
        isError,
        isRefetching,
        isLoading,
    } = useQuery({
        queryKey: [
            'folders',
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
            globalFilter
        ],
        queryFn: async () => {
            let sortCriteria = 'title asc';
            if (sorting?.length > 0) {
                const {id, desc} = sorting[0];
                sortCriteria = `${id} ${desc ? 'desc' : 'asc'}`;
            }

            const res = await getFolders({
                query: globalFilter,
                sort: sortCriteria,
                start: pagination.pageIndex === 0 ? 0 : pagination.pageIndex * pagination.pageSize,
                rows: pagination.pageSize,
                documentType: '(Folder)',
                folderType: "(!ProjectArcheology !ProjectGeneral)",
                fq: [],
                museums: myMuseums?.map(m => m.id).join(','),
                status: '(-trash)'
            });

            return {
                folders: res.count === 0 ? [] : res['models'],
                meta: {
                    totalRowCount: res.count
                }
            };
        },
        enabled: myMuseums?.length > 0,
    });

    const getMuseumColumn = useCallback(() => {
        return {
            id: 'museum',
            header: t("columnMuseum", "Museum"),
            enableEditing: false,
            enableSorting: false,
            accessorFn: (row) => {
                return getMuseumNameFromCollectionId({
                    museums: myMuseums,
                    museumCollections: museumCollections,
                    collectionId: row.collectionId
                });
            }
        };
    }, [museumCollections, myMuseums, t]);

    const FolderNameCell = useCallback(({renderedCellValue}) => (
        <ColumnFolderNameWithIcon folderName={renderedCellValue}/>
    ), []);

    const columns = useMemo(() => {
        return [
            ...getTableColumnsBase(),
            {
                id: 'title',
                header: t('columnFolderName', 'Mappenavn'),
                accessorKey: 'title',
                Cell: FolderNameCell,
                grow: false
            },
            getMuseumColumn(),
            {
                id: 'createdAt',
                header: t('columnCreatedAt', 'Opprettet'),
                accessorKey: 'createdAt',
                enableSorting: false,
                enableHiding: false,
                accessorFn: row => format(row.createdAt, 'P'),
                Cell: ({renderedCellValue}) => {
                    return renderedCellValue;
                },
            },
            {
                id: 'status',
                header: t('columnFolderTypePrivate', 'Privat'),
                accessorKey: 'status',
                enableSorting: false,
                accessorFn: row => {
                    return row.status === 'private' ? t('columnYes', 'Ja') : t('columnNo', 'Nei')
                },
                Cell: ({renderedCellValue}) => {
                    return renderedCellValue;
                },
            },
            {
                id: 'createdBy',
                header: t('columnCreatedBy', 'Opprettet av'),
                accessorKey: 'createdByName',
                enableSorting: false
            }

        ];
    }, [getMuseumColumn, t, FolderNameCell]);

    const translations = useMemo(() => getTableTranslations(t), [t]);

    const tableConfig = {
        columns,
        data: folders,
        defaultColumn: {
            minSize: '20px',
            maxSize: '300px',
            size: '280px',
        },
        enableStickyHeader: true,
        enableDensityToggle: false,
        enableColumnFilters: false,
        enableFullScreenToggle: false,
        enableColumnActions: false,
        enableRowSelection: true,
        enableMultiRowSelection: false,
        enableSelectAll: false,
        enableHiding: false,
        localization: translations,
        globalFilterFn: 'contains',
        enableGlobalFilter: true,
        manualFiltering: true,
        manualPagination: true,
        enableMultiSort: false,
        manualSorting: true,
        muiTablePaperProps: {
            sx: {
                'display': 'flex',
                'flexDirection': 'column',
            }
        },
        muiToolbarAlertBannerProps: isError
            ? {color: 'error', children: 'Error loading data'}
            : {sx: {display: 'none'}},
        getRowId: row => row.id,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination,
        onSortingChange: (newSorting) => {
            setSorting(newSorting);
        },
        onGlobalFilterChange: setGlobalFilter,
        rowCount: meta?.totalRowCount ?? 0,
        initialState: {
            density: 'compact',
            columnVisibility: {id: false, collectionId: false, uniqueId: false},
            columnOrder: ['id', 'collectionId', 'uniqueId', 'title', 'description', 'status', 'museum']
        },
        state: {
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            rowSelection,
            globalFilter
        },
    };

    const table = useMaterialReactTable(tableConfig);

    /**
     * Hook used to get information about the folder selected by the user.
     */
    useEffect(() => {
        const selectedRows = table.getSelectedRowModel().rows;
        if (!selectedRows || selectedRows?.length === 0) {
            return;
        }

        const selectedRow = selectedRows[0]?.original;
        if (!selectedRow) {
            return;
        }
        setFolderCallback(selectedRow);
    }, [rowSelection, setFolderCallback, table]);

    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto'
        }}>
        <MaterialReactTable table={table}/>
    </Box>;
};

FoldersTable.propTypes = {
    t: PropTypes.func.isRequired,
};

export {FoldersTable};