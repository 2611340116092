import React, {useState} from "react";
import {SELECT_FIELDS, STEP_FORWARD, useBatchEditDispatch, useBatchEditState,} from "./batchEditContext";
import {useDocumentTranslation} from "./documentContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import {ListItemButton} from "@mui/material";

const intersection = (a, b) => a.filter((value) => -1 !== b.indexOf(value));

/**
 * Renders a component for selecting fields in a batch edit process.
 *
 * @param {Object} props - The properties object.
 * @param {Function} props.onCancel - The function to call when the cancel button is clicked.
 * @return {JSX.Element} The rendered component.
 */
export const BatchEditSelectFields = ({onCancel}) => {
        const {availableFields, selectedFields} = useBatchEditState();
        const dispatch = useBatchEditDispatch();
        const [checked, setChecked] = useState([]);
        const t = useDocumentTranslation();

        const fieldsNotSelected = availableFields.filter(
            (availableField) => !selectedFields.includes(availableField)
        );

        const availableChecked = intersection(checked, fieldsNotSelected);

        const onContinue = () => {
            dispatch({type: SELECT_FIELDS, fields: availableChecked});
            dispatch({type: STEP_FORWARD});
            setChecked([]);
        };

        const handleCheck = (value) => {
            if (checked.includes(value)) {
                setChecked(checked.filter((c) => c !== value));
            } else {
                setChecked([...checked, value]);
            }
        };

        const customList = (items) => (
            <List dense component="div" role="list">
                {items.map((item) => {
                    const labelId = `transfer-list-item-${item.name}-label`;

                    return (
                        <ListItemButton
                            key={item.name}
                            role="listitem"
                            onClick={() => handleCheck(item)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{"aria-labelledby": labelId}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={t(item.name)}/>
                        </ListItemButton>
                    );
                })}
                <ListItem/>
            </List>
        );

        return (
            <Box>
                <Box sx={{display: "flex", marginBottom: 2, marginTop: 2}}>
                    <Box m={"auto"}>
                        <Card sx={{
                            width: 250,
                            minHeight: 500,
                            height: "90%",
                            overflow: "auto",
                        }}>
                            <CardHeader
                                subheader={t('subHeaderAvailableFields', 'Tilgjengelige felter')}
                                title={fieldsNotSelected.length}
                            />
                            <Divider/>
                            {customList(fieldsNotSelected)}
                        </Card>
                    </Box>
                </Box>
                <Toolbar>
                    <Box flexGrow={1}/>
                    <Button onClick={onCancel}>{t("cancel", "Avbryt")}</Button>
                    <Button
                        color={"secondary"}
                        variant={"contained"}
                        sx={{marginLeft: '8px',}}
                        onClick={onContinue}
                        disabled={0 === checked.length}
                    >
                        {t("continue", "Fortsett")}
                    </Button>
                </Toolbar>
            </Box>
        );
    }
;
