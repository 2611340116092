import {damsFetch} from "../app/damsFetch";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {useTranslation} from "react-i18next";

/**
 * A hook that provides functions for sending emails, and for displaying
 * a snackbar message after the email has been sent.
 *
 * @returns An array of two functions. The first function is `sendEmail`, which
 * sends an email to the given recipients with the given subject and body.
 * The second function is `sendSingleMail`, which sends an email to a single
 * recipient with the given subject and body.
 */
export const useEmail = () => {
    const snackbarDispatch = useSnackbarDispatch();
    const {t} = useTranslation("dams.email", {useSuspense: false});

    const showSnackbar = (title, data, messageType) => {
        let message = t("shareSnackSuccessMessage", "Mail sendt til") + ": ";
        if (messageType === "error") {
            message = t("shareSnackErrorMessage", "Mailutsendelse feilet til") + ": ";
        } else if (messageType === "majorerror") {
            t(
                "shareSnackDefaultErrorMessage",
                "Utsendelse av e-mail feilet, kontakt system administrator."
            );
            messageType = "error";
        }
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: title,
                body: message + data,
                type: messageType,
            },
        });
    };

    /**
     * Displays a "statusreport" when the e-mails have been sent.
     * @param data
     * @param snackbarTitle
     */
    const showStatusReport = (data, snackbarTitle) => {
        const failed = data
            .filter((r) => r.success === false)
            .map((i) => i.recipient);
        if (failed && failed.length > 0) {
            showSnackbar(snackbarTitle, failed, "error");
        } else {
            showSnackbar(
                snackbarTitle,
                data.map((i) => i.recipient),
                "success"
            );
        }
    };

    /**
     * Sends an email to a single recipient with the given subject and body.
     *
     * @param {string} subject The subject of the email.
     * @param {string} message The body of the email.
     * @param {string} recipient The recipient of the email.
     * @returns {Promise} A promise that resolves with the response from the backend.
     */
    const sendSingleMail = async (subject, message, recipient) => {
        try {
            const opts = {
                method: "POST",
                body: JSON.stringify({
                    recipients: [recipient],
                    subject: subject,
                    body: message,
                })
            };
            return await damsFetch("/share/email", opts);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    /**
     * Sends an email to multiple recipients with the given subject and body.
     * Also displays a snackbar message after the email has been sent.
     * @param {string} subject The subject of the email.
     * @param {string} message The body of the email.
     * @param {string[]} recipients The recipients of the email.
     * @param {string} snackbarTitle The title of the snackbar message.
     */
    const sendEmail = (subject, message, recipients, snackbarTitle) => {
        if (!recipients || recipients.length === 0) {
            return;
        }

        damsFetch("/share/email", {
            method: "POST",
            body: JSON.stringify({
                recipients: recipients,
                subject: subject,
                body: message,
            }),
        }).then((data) => {
            if (data.error) {
                showSnackbar(
                    snackbarTitle,
                    t("Utsendelse av e-mail feilet, kontakt system administrator."),
                    "majorerror"
                );
            } else {
                showStatusReport(data, snackbarTitle);
            }
        });
    };

    return [sendEmail, sendSingleMail];
};
