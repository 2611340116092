import {InputPersons} from "../person/InputPersons";

/**
 * Renders a FormikSelect component for selecting the responsible person/company
 * associated with a copyright clause.
 *
 * @param {Object} props - The props object.
 * @param {function} props.t - The translation function.
 * @param {number} props.ix - The index of the copyright clause.
 * @param {str} props.size - The input field size (small, medium, large).
 * @return {JSX.Element} The rendered FormikSelect component.
 */
export const FormikSelectCopyrightResponsible = ({t, ix, size}) => {
    const k = `copyrightResponsible${ix}`;

    return <InputPersons disabled={false}
                         label={t('lblPersonCompanyClauseAssociated', 'Person eller firma tilknyttet klausulen')}
                         formikKey={k}
                         createLabel={t('lblAddPersonCompany', 'Legg til person/firma')}
                         alwaysActive={true}
                         fullWidth={true}
                         size={size}/>;
};