import React from "react";
import {Tooltip} from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LockIcon from '@mui/icons-material/Lock';
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";

import {red} from '@mui/material/colors';
import {useStyles} from "./projectCardStyles";
import PropTypes from "prop-types";

const redColor = red[400];


/**
 * Displays a project icon in a search result card.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.model - The model object containing the project information.
 * @param {boolean} [props.active=true] - Whether the project is active or not.
 *
 * @return {JSX.Element} The rendered project icon component.
 */
const ResultCardProjectIcon = ({model, active = true}) => {
    const classes = useStyles();

    /**
     * Returns a lock icon component if the project is not active.
     *
     * If the project is active, an empty component is returned.
     *
     * @return {JSX.Element} The lock icon component or an empty component.
     */
    const getLockIcon = () => {
        if (active) {
            return <></>;
        } else {
            return (<Box sx={{
                    display: 'flex',
                    width: '100%',
                    marginTop: -5,
                    justifyContent: 'right'
                }}>
                    <Avatar sx={{backgroundColor: redColor}}>
                        <LockIcon/>
                    </Avatar>
                </Box>
            );
        }
    };

    return (
        <>
            <Tooltip title={model.title}>
                <div className={classes.fileThumbContainer}>
                    <AccountTreeIcon sx={{fontSize: 135, color: "#bbb"}}/>
                    {getLockIcon()}
                </div>
            </Tooltip>
        </>
    );
};

ResultCardProjectIcon.propTypes = {
    "model": PropTypes.object.isRequired,
    "active": PropTypes.bool,
};

export {ResultCardProjectIcon};