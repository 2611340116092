import React from "react";
import {CHOOSE_COLLECTIONS, useDocumentDispatch} from "./documentContext";
import {BatchEditStepper} from "./BatchEditStepper";
import {useBatchEditState} from "./batchEditContext";
import {BatchEditSelectFields} from "./BatchEditSelectFields";
import {BatchEditForm} from "./BatchEditForm";
import {FormMetadata} from "../metadata/FormMetadata";
import {BatchEditActions} from "./BatchEditActions";
import useDeepCompareEffect from "use-deep-compare-effect";
import Box from "@mui/material/Box";
import {Stack, useMediaQuery} from "@mui/material";

/**
 * @function
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.models - The documents to be edited in batch mode.
 * @param {Function} props.onHide - Callback function to be called when the user wants to hide the batch edit dialog.
 * @param {Function} props.onComplete - Callback function to be called when all documents are finished.
 * @returns {JSX.Element} The rendered component.
 * @description
 * This component renders a dialog for batch editing of documents.
 * It renders a stepper with three steps.
 * In the first step, the user can select which fields to edit.
 * In the second step, the user can edit the selected fields in a form.
 * When all documents are finished, the onComplete callback is called.
 */
export const BatchEdit = ({models, onHide, onComplete}) => {
    const {activeStepIndex} = useBatchEditState();
    const dispatch = useDocumentDispatch();
    const smallScreen = useMediaQuery("(max-width: 768px)");

    useDeepCompareEffect(() => {
        if (0 < models.length) {
            dispatch({
                type: CHOOSE_COLLECTIONS,
                collectionIds: [...new Set(models.map((model) => model.collectionId))],
            });
        }
    }, [models]);

    const step =
        0 === activeStepIndex ? (
            <BatchEditSelectFields onCancel={onHide}/>
        ) : (
            <BatchEditForm models={models} onComplete={onComplete}>
                <Stack direction={"column"}>
                    <Box sx={{
                        overflowY: 'auto',
                        maxHeight: smallScreen ? '90%' : '800px'
                    }}>
                        <FormMetadata batchEdit={true}/>
                    </Box>
                    <BatchEditActions
                        onCancel={onHide}
                        numberOfDocuments={models.length}
                    />
                </Stack>
            </BatchEditForm>
        );
    return (
        <>
            <Box>
                <BatchEditStepper/>
            </Box>
            {step}
        </>
    );
};
