import {SET_ERROR, SET_RESULT, useSearchDispatch, useSearchState,} from "./SearchContext";
import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import useDeepCompareEffect from "use-deep-compare-effect";
import {clientLog} from "../clientLog";
import {addFqParams} from "../addFqParams";


const sumFacets = facets => {
    let successCounter = 0;
    let falseCounter = 0;
    for (let i = 0, max = facets.length; i < max; i++) {
        const f = facets[i];

        successCounter += f?.true?.count || 0;
        falseCounter += f?.false?.count || 0;
    }
    return {
        true: {
            count: successCounter
        },
        false: {
            count: falseCounter
        }
    };
};


/**
 * Custom hook to fetch search results.
 *
 * @param {{
 *     timestamp: string,
 *     query: string,
 *     sort: string,
 *     fl: string,
 *     start: number,
 *     rows: number,
 *     documentType: string,
 *     facetField: string,
 *     facetLimit: number,
 *     fq: object,
 *     statsField: string,
 *     museums: string[],
 *     expand: boolean,
 *     triggerSearch: boolean,
 *     onlyMine: boolean,
 *     onlyWriteable: boolean
 * }} props
 *
 * @return {void}
 */
export const useSearch = ({
                              timestamp,
                              query,
                              sort,
                              fl,
                              start = 0,
                              rows = 100,
                              documentType,
                              facetField,
                              facetLimit = 1000,
                              fq,
                              statsField,
                              museums = [],
                              expand = false,
                              triggerSearch = null,
                              onlyMine = false,
                              onlyWriteable = true
                          }) => {
    const searchDispatch = useSearchDispatch();
    const {searching} = useSearchState();

    useDeepCompareEffect(() => {
        if (!documentType || !searching) {
            return;
        }

        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: query,
                sort: sort,
                fl: fl,
                expand: expand,
                start: start,
                rows: rows,
                documentType: `(${documentType})`,
                facetField: facetField,
                facetLimit: facetLimit,
                statsField: statsField,
                museums: museums,
                onlyMine: onlyMine,
                onlyWriteable: onlyWriteable
            })
        );

        addFqParams(fq, searchParams);

        damsFetch(`/search?${searchParams.toString()}`)
            .then((json) => {
                const {facetFields, statsFields} = json;

                const projectStates = sumFacets([
                    getFacet(facetFields['archeologyprojectrefActive']),
                    getFacet(facetFields['generalprojectrefActive'])
                ]);

                searchDispatch({
                    type: SET_RESULT,
                    count: json.count,
                    models: json.models,
                    facets: {
                        persons: getFacet(facetFields["personsReferenceTitle"]),
                        producers: getFacet(facetFields["producerReferenceTitle"]),
                        places: getFacet(facetFields["placesReferenceTitle"]),
                        licenses: getFacet(facetFields["licensesReferenceTitle"]),
                        subjects: getFacet(facetFields["subjectsReferenceTitle"]),
                        creators: getFacet(facetFields["createdByName"]),
                        languages: getFacet(facetFields["languagesTitle"]),
                        mimeTypes: getFacet(facetFields["mediaeReferenceMimeType"]),
                        archeologyProjectTypes: getFacet(facetFields["archeologyprojectrefType"]),
                        generalProjectTypes: getFacet(facetFields["generalprojectrefType"]),
                        generalprojectrefStats: getFacet(facetFields["generalprojectrefActive"]),
                        archeologyprojectrefStats: getFacet(facetFields["archeologyprojectrefActive"]),
                        projectStates: projectStates,
                        durationSeconds: getFacet(
                            facetFields["mediaeReferenceDurationSeconds"]
                        ),
                        documentTypes: getFacet(facetFields['documentType']),
                        copyrightTypes: getFacet(facetFields['copyrightType']),
                        copyrightClauses: getFacet(facetFields['copyrightInfoTitle'])
                    },
                    stats: {
                        createdAt: getStats(statsFields["createdAt"]),
                        updatedAt: getStats(statsFields["updatedAt"]),
                        productionDate: getStats(statsFields["productionDate"]),
                    },
                });
            })
            .catch((error) => {
                clientLog('error', error, 'useSearch');
                searchDispatch({type: SET_ERROR});
            });

        const getFacet = (facet) => {
            let personsCount = {};

            for (let i = 0; i < facet.length; i += 2) {
                personsCount[facet[i]] = {
                    count: facet[i + 1],
                };
            }
            return personsCount;
        };

        const getStats = (stats) => ({
            min: stats.min,
            max: stats.max,
        });
    }, [
        timestamp,
        query,
        sort,
        fl,
        start,
        rows,
        documentType,
        facetField,
        facetLimit,
        fq,
        expand,
        searchDispatch,
        JSON.stringify(museums),
        triggerSearch,
        onlyMine,
        searching
    ]);
};
