import Stack from "@mui/material/Stack";

import {FormikSelectCopyrightClause} from "./FormikSelectCopyrightClause";
import React, {useState} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {FormikSelectCopyrightResponsible} from "./FormikSelectCopyrightResponsible";
import {HIDE_ADD_COPYRIGHT_CLAUSE_FIELD, useCopyrightDispatch} from "./copyrightContext";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Box from "@mui/material/Box";
import {FormikDateField} from "../form/FormikDateField";
import {useFormikContext} from "formik";

/**
 * Renders a wrapper component for a copyright clause with date and responsible fields.
 *
 * @param {Object} props - The component props.
 * @param {function} props.t - The translation function.
 * @param {number} props.ix - The index of the clause.
 * @param {string} props.collectionId - The ID of the collection.
 * @return {JSX.Element} The rendered wrapper component.
 */
export const CopyrightClauseWrapper = ({t, ix, collectionId}) => {

    const [showDateAndResponsible, setShowDateAndResponsible] = useState(true);
    const copyrightDispatch = useCopyrightDispatch();

    const {values, setFieldValue} = useFormikContext();

    /**
     * Reconstructs the Formik fields for the copyright clauses by deleting all
     * existing fields and then recreating them with the updated data.
     *
     * @param {number} numCopyrightClauses - The number of copyright clauses.
     * @param {Object[]} editedCopyrightClauses - The updated copyright clause data.
     */
    function _recreateCopyrightClauses(numCopyrightClauses, editedCopyrightClauses) {
        // Delete all copyrightClauseIx fields.
        for (let i = 0, max = numCopyrightClauses; i < max; i++) {
            setFieldValue(`copyrightClause${i}`, undefined).then();
        }

        // Create new copyrightClauseIx fields, according to the updated data.
        if (editedCopyrightClauses.length > 0) {
            for (let a = 0, max = editedCopyrightClauses.length; a < max; a++) {
                setFieldValue(`copyrightClause${a}`, editedCopyrightClauses[a]).then();
            }
        } else {
            for (let n = 0, max = numCopyrightClauses; n < max; n++) {
                setFieldValue(`copyrightClause${n}`, undefined).then();
            }
        }
    }

    
    /**
     * Handler function for the FormikSelectCopyrightClause component.
     *
     * Updates the Formik values object, by either:
     * - Adding a new copyright clause to the list.
     * - Updating an existing copyright clause in the list.
     * - Deleting a copyright clause from the list.
     *
     * Triggers the hiding of the add copyright clause button.
     *
     * @param {Object | undefined | null} value - The selected copyright clause data.
     * @param {number} ix - The index of the copyright clause in the list.
     */
    const onCopyrightClauseChangeHandler = (value, ix) => {
        if (value !== undefined && value !== null) {
            setShowDateAndResponsible(true);

            if (values['copyrightInfo']) {
                // If a list of copyright clauses already exists, update the clause at position ix.
                let updatedCopyrightInfo = [...values['copyrightInfo']];
                updatedCopyrightInfo[ix] = value;
                setFieldValue('copyrightInfo', updatedCopyrightInfo).then();
            } else {
                // If no list of copyright clauses exists, create a new one.
                setFieldValue('copyrightInfo', [value]).then();
            }
        } else {
            // Value is undefined or null, delete the corresponding entry.
            let editedValues = {...values};

            // Update and synchronize the "copyrightClause" field.copyrightClauseIx in the Formik values object.
            const numCopyrightClauses = editedValues['copyrightInfo'].length;

            let editedCopyrightClauses = [];
            for (let j = 0; j < numCopyrightClauses; j++) {
                const key = `copyrightClause${j}`
                if (j === ix) {
                    setFieldValue(key, undefined).then();
                } else if (j !== ix
                    && editedValues[key]
                    && editedValues[key]
                    && editedValues[key] !== null) {
                    editedCopyrightClauses.push(editedValues[key]);
                }
            }

            _recreateCopyrightClauses(numCopyrightClauses, editedCopyrightClauses);

            // Update the field copyrightInfo in the Formik values object.
            editedValues['copyrightInfo'].splice(ix, 1);
            setFieldValue('copyrightInfo', editedValues['copyrightInfo']).then();
        }

        copyrightDispatch({
            type: HIDE_ADD_COPYRIGHT_CLAUSE_FIELD
        });
    };

    /**
     * Sets the value of the specified value field, and updates the formData object.
     * @param i    int The index-value in the array of fields.
     * @param name  str The name of the field.
     * @param value str The value.
     */
    const setDateFieldValue = (i, name, value) => {
        if (!values['copyrightInfo'] || values['copyrightInfo'].length === 0) {
            values['copyrightInfo'] = [];
        }
        values['copyrightInfo'][i][name] = value;
    };

    /**
     * Adds the "from" date value to the formData object.
     * @param value
     */
    const fromDateChangeHandler = (value) => {
        setDateFieldValue(ix, 'fromDate', value);
    };

    /**
     * Adds the "to" date value to the formData object.
     * @param value
     */
    const toDateChangeHandler = (value) => {
        setDateFieldValue(ix, 'toDate', value);
    };

    return <Stack sx={{width: '100%'}}>
        <FormikSelectCopyrightClause t={t}
                                     copyrightClauseOnChangeHandler={onCopyrightClauseChangeHandler}
                                     ix={ix}
                                     collectionId={collectionId}
                                     size={"small"}
        />
        {showDateAndResponsible &&
            <LocalizationProvider dateAdapter={AdapterDateFns}>

                <Stack direction={"row"}>
                    <SubdirectoryArrowRightIcon sx={{color: '#ddd'}} fontSize={"large"}/>
                    <FormikDateField formikKey={`copyrightFromDate${ix}`}
                                     label={t('copyrightClauseValidFrom', 'Klausul gjelder fra')}
                                     disabled={false}
                                     helperText={t("dateformatPlaceholder", "dd.mm.yyyy | yyyy")}
                                     placeholder={t("dateformatPlaceholder", "dd.mm.yyyy | yyyy")}
                                     onChangeCallback={fromDateChangeHandler}
                                     size={"small"}
                    />
                    <Box sx={{marginLeft: 1}}/>
                    <FormikDateField formikKey={`copyrightToDate${ix}`}
                                     label={t('copyrightClauseValidTo', 'Klausul gjelder til')}
                                     disabled={false}
                                     helperText={t("dateformatPlaceholder", "dd.mm.yyyy | yyyy")}
                                     placeholder={t("dateformatPlaceholder", "dd.mm.yyyy | yyyy")}
                                     onChangeCallback={toDateChangeHandler}
                                     size={"small"}
                    />
                </Stack>
                <Stack direction={"row"} sx={{width: '100%'}}>
                    <SubdirectoryArrowRightIcon sx={{color: '#ddd'}} fontSize={"large"}/>
                    <Box sx={{width: '100%'}}>
                        <FormikSelectCopyrightResponsible t={t}
                                                          ix={ix}
                                                          size={"small"}/>
                    </Box>
                </Stack>

            </LocalizationProvider>
        }
    </Stack>;
}