import React from "react";
import {IconButton, Tooltip,} from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import PropTypes from "prop-types";

/**
 * A button to toggle the search result view mode to table view.
 *
 * The button is a IconButton wrapped in a Tooltip with the text "Vis liste".
 * The button is either primary or default color, depending on the current view mode.
 * When the button is clicked, the onToggle callback is called with the argument "table".
 *
 * @param {function} onToggle - The function to call when the button is clicked.
 * @param {string} viewMode - The current view mode.
 * @returns {ReactElement} A React element representing the button.
 */
const TableViewButton = ({onToggle, viewMode}) => {
    /**
     * Returns the color of the button, based on the current view mode.
     * If the current view mode is "table", it returns "primary", otherwise it returns "default".
     * @returns {string} The color of the button.
     */
    const color = () => ("table" === viewMode ? "primary" : "default");

    return (
        <Tooltip title="Vis liste">
            <IconButton
                aria-label={"choose-list-view"}
                onClick={() => onToggle("table")}
                color={color()}
                size="large"
            >
                <ViewListIcon/>
            </IconButton>
        </Tooltip>
    );
};

TableViewButton.propTypes = {
    "onToggle": PropTypes.func.isRequired,
    "viewMode": PropTypes.string.isRequired
};

export {TableViewButton};

