import React from "react";
import Box from "@mui/material/Box";

/**
 * A grid component for displaying search results.
 *
 * It displays a grid of items which automatically fills the available space.
 * The grid is scrollable and has a minimum height of 0.
 *
 * @param {React.ReactNode} children - The items to be displayed in the grid.
 * @returns {React.ReactElement} - A `Box` component with the grid layout.
 */
export const ResultGrid = ({children}) => {
    return (
        <Box sx={{
            padding: {
                xs: 2
            },
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
            gridAutoRows: 'max-content',
            gap: {
                xs: '.5rem',
                sm: '1rem',
            },
            minHeight: '0',
            flexShrink: 1,
            flexGrow: 1,
            flexBasis: 0,
            overflowY: 'scroll',
            overflowX: 'hidden',
            scrollbarColor: '#009688 #ddd',
            scrollbarWidth: 'thin'
        }}>
            {children.map((child) => (
                <Box key={child.props.model.documentType + "_" + child.key}>
                    {child}
                </Box>
            ))}
        </Box>
    );
};
