/**
 * @function createReferencedObjects
 * @description Creates referenced objects (persons, places, subjects, producers) for the given models,
 *              based on the selected fields and the callback function provided.
 * @param {Array<Object>} selectedFields An array of objects, each describing a field that is selected
 *                                       for editing.
 * @param {Array<Object>} models An array of objects, each representing a document that is being edited.
 * @param {Object} newValues An object containing the new values for the given fields.
 * @returns {Promise<Object>} A promise that resolves with the `newValues` object, with the created
 *                            referenced objects added to the relevant field.
 */
export const createReferencedObjects = (selectedFields, models, newValues) => {
    const involvedCollectionIds = [...new Set(models.map(m => m.collectionId))];
    const involvedArrayFields = selectedFields.find(f =>
        f.name === 'persons'
        || f.name === 'producer'
        || f.name === 'places');

    // Append persons, if necessary:
    if (involvedArrayFields && involvedArrayFields.name === 'persons') {
        const persons = [...newValues.persons];
        const personsToBeCreated = _getMissingPersons(persons, involvedCollectionIds);
        const personsArr = [...new Set([...persons, ...personsToBeCreated])];
        newValues.persons = personsArr;
    }

    // Append producers, if necessary:
    if (involvedArrayFields && involvedArrayFields.name === 'producer') {
        const producers = [...newValues.producer];
        const createdProducers = _getMissingPersons(producers, involvedCollectionIds);
        newValues.producer = [...new Set([...producers, ...createdProducers])];
    }

    // Append places, if necessary:
    if (involvedArrayFields && involvedArrayFields.name === 'places') {
        const places = [...newValues.places];
        const createdPlaces = _getMissingPlaces(places, involvedCollectionIds);
        newValues.places = [...places, ...createdPlaces];
    }

    return newValues;
};

/**
 * For each place in `places`, checks if there is an entry in the array with the same collectionId as each of the `involvedCollectionIds`.
 * If there is not, a new entry is created and added to the `placesToBeCreated` array.
 * The new entry is created by copying the place object, deleting the `collectionId` property, and adding the missing collectionId.
 * The `placesToBeCreated` array is then returned.
 * @param {Array} places - The array of place objects to check.
 * @param {Array} collectionIds - The array of collectionIds to check against.
 * @returns {Array} The array of missing place objects.
 */
const _getMissingPlaces = (places, collectionIds) => {
    let placesToBeCreated = [];
    for (let i = 0, max = collectionIds.length; i < max; i++) {
        const collectionId = collectionIds[i];
        placesToBeCreated = [...new Set([...placesToBeCreated,
            ...places.filter(p => p.collectionId !== collectionId).map(p => ({
                ...p,
                collectionId: collectionId
            }))])];
    }
    return placesToBeCreated;
};

/**
 * For each person in `persons`, checks if there is an entry in the array with the same collectionId as each of the `involvedCollectionIds`.
 * If there is not, a new entry is created and added to the `missingPersons` array.
 * The new entry is created by copying the person object, deleting the `collectionId` property, and adding the missing collectionId.
 * The `missingPersons` array is then returned.
 * @param {Array} persons - The array of person objects to check.
 * @param {Array} collectionIds - The array of collectionIds to check against.
 * @returns {Array} The array of missing person objects.
 */
const _getMissingPersons = (persons, collectionIds) => {
    if (persons.length === 0) {
        return [];
    }

    const mappedByCollectionAndId = persons.map(p => ({collectionId: p.collectionId, id: p.reference.id}));

    const groupedByCollection = mappedByCollectionAndId.reduce((acc, item) => {
        const {collectionId, id} = item;
        if (!acc[collectionId]) {
            acc[collectionId] = new Set();
        }
        acc[collectionId].add(id);
        return acc;
    }, {});

    const allIds = new Set(mappedByCollectionAndId.map(item => item.id));

    collectionIds.forEach(collectionId => {
        if (!groupedByCollection[collectionId]) {
            groupedByCollection[collectionId] = new Set();
        }
    });

    const missingInCollections = {};
    for (const collectionId of collectionIds) {
        const idsSet = groupedByCollection[collectionId];
        const missingIds = [...allIds].filter(id => !idsSet.has(id));
        missingInCollections[collectionId] = missingIds;
    }

    // Step 4: Consolidate missing elements across collections
    const consolidatedMissing = {};
    allIds.forEach(id => {
        consolidatedMissing[id] = [];
        collectionIds.forEach(collectionId => {
            if (!groupedByCollection[collectionId].has(id)) {
                consolidatedMissing[id].push(collectionId);
            }
        });
        if (consolidatedMissing[id].length === 0) {
            // Remove the id if it's not missing in any required collection
            delete consolidatedMissing[id];
        }
    });

    const newPersons = [];
    Object.keys(consolidatedMissing).forEach(id => {
        const person = persons.find(p => p.reference.id === parseInt(id));
        if (person) {
            const missingCollectionIds = consolidatedMissing[id];
            for (let j = 0, max = missingCollectionIds.length; j < max; j++) {
                const p = {...person};
                p.collectionId = parseInt(missingCollectionIds[j]);
                newPersons.push(p);
            }
        }
    });

    return newPersons;
};

