import React, {useState} from "react";
import {FormikSubmitButton} from "../form/FormikSubmitButton";
import {useDocumentTranslation} from "./documentContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Stack} from "@mui/material";


/**
 * A component that renders a `FormControlLabel` with a checkbox and a
 * label that asks the user to confirm that they want to save the changes
 * to the batch edit documents.
 *
 * The component also renders two buttons, one for cancelling the batch
 * edit and one for saving the changes.
 *
 * @param {Object} props Component props
 * @param {function} props.onCancel Function that will be called when the
 * user clicks the cancel button.
 * @param {number} props.numberOfDocuments The number of documents that
 * are being edited in the batch edit.
 *
 * @returns {ReactElement} A `Box` component containing the checkbox and
 * the two buttons.
 */
export const BatchEditActions = ({onCancel, numberOfDocuments}) => {
    const t = useDocumentTranslation();
    const [confirmSave, setConfirmSave] = useState(false);

    const handleChange = () => setConfirmSave((cs) => !cs);

    return (
        <Box sx={{
            width: '100%',
            backgroundColor: '#f5f5f5',
            paddingRight: '32px'
        }}>
            <Stack direction={"row"} justifyContent={"end"}>
                <Box>
                    <FormControlLabel
                        disabled={confirmSave}
                        labelPlacement={"start"}
                        control={
                            <Checkbox
                                checked={confirmSave}
                                onChange={handleChange}
                                color={"secondary"}
                            />
                        }
                        label={t(
                            "batchEditConfirm",
                            "Du er i ferd med å endre {{count}} objekter. Er du sikker på dette?",
                            {
                                count: numberOfDocuments,
                            }
                        )}
                    />
                </Box>
            </Stack>
            <Stack direction={"row"} justifyContent={"end"}>
                <Button onClick={onCancel}>{t("cancel", "Avbryt")}</Button>
                <FormikSubmitButton
                    color={"secondary"}
                    variant={"contained"}
                    disabled={!confirmSave}
                >
                    {t("save", "Lagre")}
                </FormikSubmitButton>
            </Stack>
        </Box>
    );
};
