import {useEffect, useState} from "react";

/**
 * Hook that returns a boolean indicating whether the document is currently visible
 * or not.
 *
 * @returns {boolean} True if the document is visible, false otherwise.
 */
export const useDocumentVisibility = () => {
    const [isDocumentVisible, setIsDocumentVisible] = useState(!document.hidden);

    const handleVisibilityChange = () => {
        setIsDocumentVisible(!document.hidden);
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return isDocumentVisible;
};