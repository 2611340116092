import React, {useEffect, useRef} from "react";
import {useTheme} from "@mui/material/styles";
import {ResultTableHead} from "./ResultTableHead";
import {
    SELECT,
    SET_SEARCHING,
    SET_SELECTED,
    SET_SORT,
    UNSELECT,
    useSearchDispatch,
    useSearchState,
} from "./SearchContext";
import {getTableColumns} from "./tableColumns";
import {ResultTableColumnSelector} from "./ResultTableColumnSelector";
import {
    SHOW,
    useSearchResultDetailsDispatch,
    useSearchResultDetailsTranslation,
} from "../search-result-details/SearchResultDetailsContext";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import {SearchSettings} from "./SearchSettings";
import {FolderSearchSettings} from "./FolderSearchSettings";
import {useLocation, useNavigate} from "react-router-dom";
import {SET_AVAILABLE_COLUMNS, useResultTableDispatch, useResultTableState} from "./ResultTableContext";
import {decamelize} from "../app/decamelize";
import {useMediaQuery} from '@mui/material';
import {getMuseumNameFromCollectionId} from "../utility";
import {useMyMuseums} from "../museum/useMyMuseums";
import {useAuthsState} from "../auths/authsContext";
import Box from "@mui/material/Box";


/**
 * The ResultTable component renders a table of search results.
 *
 * It is a thin wrapper around the Material-UI Table component, and is
 * responsible for rendering the table columns, rows, and header.
 *
 * The component accepts the following props:
 * - dataRows: An array of objects, where each object represents a search
 * result.
 * - folderId: The id of the folder that the search results are for. If
 * not provided, the component will render the search results for all
 * folders.
 *
 * The component uses the following hooks:
 * - useSearchState: To get the search state, which includes the current
 * sort order and the selected search results.
 * - useSearchDispatch: To dispatch actions to update the search state.
 * - useResultTableDispatch: To dispatch actions to update the result table
 * state, which includes the available columns and the columns to render.
 * - useResultTableState: To get the result table state, which includes the
 * available columns and the columns to render.
 * - useMyMuseums: To get the list of museums that the user has access to.
 * - useAuthsState: To get the list of museum collections that the user has
 * access to.
 *
 * The component renders the following elements:
 * - A Material-UI Toolbar component, which contains a ResultTableColumnSelector
 * component.
 * - A Material-UI Table component, which renders the table columns, rows,
 * and header.
 * - A ResultTableHead component, which renders the table header.
 * - A TableBody component, which renders the table rows.
 */
export const ResultTable = ({folderId, dataRows}) => {
    const myMuseums = useMyMuseums({});
    const {museumCollections} = useAuthsState();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const folderSearchSettings = FolderSearchSettings('dams.folderSearchResults.config');
    const searchSettings = SearchSettings('dams.searchResults.config');

    const {sort, selected} = useSearchState();
    const searchDispatch = useSearchDispatch();
    const [orderBy, order] = sort.split(" ");
    const dispatch = useSearchResultDetailsDispatch();
    const t = useSearchResultDetailsTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const tableColumns = getTableColumns();

    const isProjectList = location.pathname === "/all-projects/";   // Listing projects

    const resultTableDispatch = useResultTableDispatch();
    const {columnsToRender} = useResultTableState();

    const contextKey = 'search';
    const filteredTableColumns = useRef();

    /**
     * Hook used to add the name of the museum, when viewing projects, as this information is not stored
     * together with the actual project data.
     */
    const appendMuseumName = dataRows => {
        if (!isProjectList || !myMuseums || !museumCollections) {
            return dataRows;
        }
        return dataRows.map(r => ({
            ...r,
            museumName: getMuseumNameFromCollectionId({
                museums: myMuseums,
                museumCollections: museumCollections,
                collectionId: r.collectionId
            })
        }));
    };

    useEffect(() => {
        if (!tableColumns) {
            return;
        }

        // Disable sorting on the "virtual column" "museumName":
        const colMuseumName = tableColumns.find(tc => tc.id === 'museumName');
        if (colMuseumName) {
            colMuseumName.disableSorting = true;
        }

        // If a column config is stored, use it!
        const savedCols = localStorage.getItem('dams.' + contextKey + '.columns');

        filteredTableColumns.current = tableColumns;

        if (savedCols) {
            filteredTableColumns.current = tableColumns.map(tc => {
                return {
                    ...tc,
                    checked: savedCols.includes(tc.id),
                    defaultHidden: true
                }
            });
        }
    }, [tableColumns]);


    /**
     * Hook used to get, and set the default available columns.
     */
    useEffect(() => {

        /**
         * Helper function to determine if a column should be rendered, based on the
         * value of `defaultHidden` and `checked`.
         *
         * @param {array} cols The array of columns to check.
         * @return {array} The array of columns with the `checked` property set
         *                 to `true` or `false` based on the rules above.
         */
        function checkActive(cols) {
            return cols.map(c => {
                let render = false;
                if (!c.checked && c.defaultHidden === false) {
                    render = true;
                }
                if (c.checked) {
                    render = c.checked;
                }
                return {...c, checked: render};
            });
        }

        let columns;

        if (isProjectList) {
            // Columns that should not be available under the list of projects,
            // are marked with the property "projectList: false" in the file tableColumns.js
            columns = filteredTableColumns.current.filter(tc => {
                return tc.projectList === isProjectList || typeof (tc.projectList) === 'undefined';
            });
            columns = checkActive(columns);
            resultTableDispatch({
                type: SET_AVAILABLE_COLUMNS,
                availableColumns: columns,
                contextKey: contextKey
            })
        } else {
            // Columns marked with projectList: true, should only appear in the project list.
            columns = checkActive(filteredTableColumns.current.filter(tc => {
                return !tc.projectList || typeof (tc.projectList) === 'undefined'
            }));
            resultTableDispatch({
                type: SET_AVAILABLE_COLUMNS,
                availableColumns: columns,
                contextKey: contextKey
            });
        }
    }, [
        isProjectList,
        resultTableDispatch,
        filteredTableColumns
    ]);


    /**
     * Set the selected items in the table.
     *
     * @param {array} selection An array of selected items.
     */
    const setSelected = (selection) => {
        searchDispatch({
            type: SET_SELECTED,
            selected: selection,
        });
    };

    /**
     * Handle request to sort table by given column.
     *
     * @param {object} _event Event object (not used)
     * @param {string} property Column name to sort by.
     */
    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === decamelize(property) && order === "asc";
        const sortOrder = isAsc ? "desc" : "asc";
        searchDispatch({
            type: SET_SORT,
            sort: `${property} ${sortOrder}`,
        });
        !folderId
            ? searchSettings.setSortColumnAndOrder(property, sortOrder)
            : folderSearchSettings.setSortColumnAndOrder(property, sortOrder);
        searchDispatch({type: SET_SEARCHING});
    };

    /**
     * Handles the select all checkbox click event.
     *
     * If the checkbox is checked, selects all rows in the table.
     * If the checkbox is unchecked, deselects all selected rows.
     *
     * @param {object} event Event object from the click event.
     * @param {boolean} event.target.checked Whether the checkbox is checked.
     */
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelected(dataRows);
            return;
        }
        setSelected([]);
    };

    /**
     * Handle checkbox click event on table row.
     *
     * @param {boolean} isItemSelected Whether the item is currently selected.
     * @param {object} model The model to select/unselect.
     */
    const handleCheck = (isItemSelected, model) => {
        if (isItemSelected) {
            searchDispatch({
                type: UNSELECT,
                model: model,
            });
        } else {
            searchDispatch({
                type: SELECT,
                model: model,
            });
        }
    };

    /**
     * Returns true if the row with the given uniqueId is selected, false otherwise.
     * @param {string} uniqueId The uniqueId of the row to check.
     * @returns {boolean} Whether the row is selected.
     */
    const isSelected = (uniqueId) =>
        Boolean(selected.find((model) => uniqueId === model.uniqueId));

    /**
     * Handles row clicks in the result table.
     * If the model is a project, navigate to the project page.
     * Otherwise, open the selected document in object view.
     * @param {object} model The model of the row that is clicked.
     */
    const handleRowClick = (model) => {
        if (
            model.documentType.toLowerCase() === "folder" &&
            model.status === "project"
        ) {
            // Projects are special folders:
            // documentType: folder
            // status: project
            navigate("/project/" + model.id);
        } else {
            // Open the selected document in object view:
            dispatch({
                type: SHOW,
                model: model,
            });
        }
    };

    /**
     * Renders the table body. If the current view is a project list, appends the museum name to the data rows.
     * @returns {JSX.Element} The rendered table body.
     */
    const getTableBody = () => {
        if (isProjectList) {
            dataRows = appendMuseumName(dataRows);
        }
        return <>
            {dataRows.map((row) => {
                const isItemSelected = isSelected(row.uniqueId);
                return (
                    <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.uniqueId}
                        selected={isItemSelected}
                        sx={{cursor: "pointer"}}
                    >
                        <TableCell
                            padding={"checkbox"}
                            onClick={() => handleCheck(isItemSelected, row)}
                        >
                            <Checkbox checked={isItemSelected}/>
                        </TableCell>
                        {columnsToRender.map((cell) => {
                            return (
                                <TableCell
                                    key={cell.id}
                                    {...cell.cellProps}
                                    align={cell.align ? cell.align : "left"}
                                    onClick={() => handleRowClick(row)}
                                >
                                    {cell.getValue(row, t)}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </>;
    };

    return (
        <Box sx={{overflow: 'hidden', display: 'flex', flexDirection: 'column', flexGrow: 1,}} id="ResultsTable-root">
            <Toolbar variant="dense" sx={{
                borderBottom: '1px solid #E0E0E0',
                "&.MuiToolbar-root": {
                    paddingLeft: 1,
                },
            }}>
                <ResultTableColumnSelector t={t}/>
            </Toolbar>
            <TableContainer sx={{flexGrow: 1}}>
                <Table
                    stickyHeader
                    aria-labelledby="search-results-title"
                    size={isSmallScreen ? "small" : "medium"}
                    aria-label="search-results-table"
                >
                    <ResultTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={dataRows.length}
                        t={t}
                    />
                    <TableBody>
                        {getTableBody()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
