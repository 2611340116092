import React, {useEffect} from "react";
import {refreshToken} from "@ekultur/authentication";
import {useAuthsDispatch, USER_LOGGED_IN} from "./authsContext";
import {useLocation} from "react-router-dom";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    root: {
        height: "100vh",
        overflowY: "hidden",
        overflowX: "hidden",
    },
}));

/**
 * Higher-order component that wraps the children with a useEffect that attempts to
 * log the user in by refreshing the token, if the user is not already logged in.
 * The effect is triggered only if the current location is not the oauth2 callback.
 * @param {Object} props
 * @param {any} children
 * @return {JSX.Element}
 */
export const WithAuthentication = ({children}) => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useAuthsDispatch();

    useEffect(() => {
        if (!location.pathname.startsWith("/oauth2/callback")) {
            refreshToken({
                apiGateway: window._env_.REACT_APP_API_GATEWAY,
                onSuccess: () => dispatch({type: USER_LOGGED_IN}),
            });
        }
    });

    return <div className={classes.root}>{children}</div>;
};
