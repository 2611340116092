import {useAuthsState} from "./authsContext";

/**
 * Hook returning true if user has admin-role in at least one museum, false otherwise.
 *
 * @returns {boolean} true if user is an admin of at least one museum, false otherwise
 */
export const useIsAdminOfAtleastOneMuseum = () => {
    const {userData} = useAuthsState();

    if (!userData || !userData.appAccess) {
        return false;
    }

    return (
        userData['appAccess'] &&
        Boolean(
            userData['appAccess'].museums?.filter((museum) =>
                museum['applications']?.find(
                    (app) =>
                        window._env_.REACT_APP_ID === app.id && app.role.name === "Admin"
                )
            ).length
        )
    );
};
