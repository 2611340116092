import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FilterCheckbox} from "./FilterCheckbox";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

/**
 * Renders a list of checkboxes based on the provided options, facets, and event handlers.
 *
 * @param {string} key - The key used to identify the facet in the facets object.
 * @param {Object} selected - The object containing the currently selected checkbox values.
 * @param {Object} opts - The options object containing the available checkbox values.
 * @param {function} callback - The event handler for checkbox clicks.
 * @return {Array} An array of JSX elements representing the checkboxes.
 */
export const renderCheckboxes = (key, selected, opts, callback) => {
    const optionsLimit = 100; // Limit the list of viewable options to max 100 items.
    return Object.keys(opts).slice(0, optionsLimit).map((value) => (
        <ListItem
            key={value}
            dense={true}
            disablegutters={"true"}
            onClick={callback(value)}
        >
            <ListItemIcon>
                <FilterCheckbox
                    selected={selected}
                    subjectsKey={key}
                    value={value}
                />
            </ListItemIcon>
            <ListItemText id={`label-${value}`}>
                {value} ({opts[value]?.count})
            </ListItemText>
        </ListItem>
    ));
};
