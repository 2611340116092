import React from "react";
import {CLOSE, useResultActionDispatch} from "./ResultActionContext";
import {ListItemIcon, MenuItem} from "@mui/material";
import PropTypes from "prop-types";

/**
 * A `MenuItem` that dispatches an action in the `ResultActionContext` on click.
 *
 * @param {{displayValue: string, actionType: string, disabled?: boolean, icon?: ReactNode}} props
 * @param {string} props.displayValue Text to display in the menu item.
 * @param {string} props.actionType The type of the action to dispatch.
 * @param {boolean} [props.disabled=false] Whether the menu item should be disabled.
 * @param {ReactNode} [props.icon=null] An icon to render in front of the menu item text.
 * @returns {ReactNode} A `MenuItem` that dispatches an action on click.
 */
const ResultAction = ({
                          displayValue,
                          actionType,
                          disabled = false,
                          icon = null,
                      }) => {
    const dispatch = useResultActionDispatch();

    /**
     * Handles a click on this `ResultAction`. Dispatches an action of type
     * `actionType` and then closes the menu.
     */
    const handleClick = () => {
        dispatch({type: actionType});
        dispatch({type: CLOSE});
    };

    /**
     * Returns an empty fragment if `icon` is falsy, otherwise a `ListItemIcon`
     * containing the given `icon`.
     *
     * @param {object} _ The MenuItem render props. Not used.
     * @return {ReactNode} A `ListItemIcon` containing the given `icon` if it is
     *     truthy, otherwise an empty fragment.
     */
    const renderIcon = (_) => {
        if (!icon) {
            return <></>;
        }
        return <ListItemIcon>{icon}</ListItemIcon>;
    };

    return (
        <MenuItem disabled={disabled} onClick={handleClick}>
            {renderIcon()}
            {displayValue}
        </MenuItem>
    );
};

ResultAction.propTypes = {
    displayValue: PropTypes.any.isRequired,
    actionType: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.object
};

export {ResultAction};