import {getWorkerConfigBase} from "./getWorkerConfigBase";

/**
 * Sends a message to the DMS status web worker to stop polling.
 * No-op if the web worker is not running.
 */
export const stopDmsStatusWorker = () => {
    if (!window._dmsStatusWorkerInstance) {
        return;
    }

    const apiGateway = window._env_.REACT_APP_DAMS_ADMIN_API;
    const workerConfig = getWorkerConfigBase(apiGateway);
    
    window._dmsStatusWorkerInstance.postMessage(JSON.stringify({
        config: workerConfig,
        action: 'stop',
        channel: 'worker',
    }));
};