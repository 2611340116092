import React from "react";
import {useTheme} from "@emotion/react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {TOGGLE_COLUMN, useResultTableDispatch, useResultTableState} from "./ResultTableContext";

/**
 * A component that renders a button with a dropdown menu, allowing the user
 * to toggle the visibility of columns in the result table.
 *
 * @param {Object} props The component props.
 * @param {Function} props.t A translation function.
 *
 * @returns {Element} The component.
 */
export const ResultTableColumnSelector = ({t}) => {
    const theme = useTheme();
    const {availableColumns} = useResultTableState();

    const [anchorEl, setAnchorEl] = React.useState(null);


    /**
     * Handles a click event on the button that triggers the menu.
     * Sets the `anchorEl` state to the element that was clicked.
     *
     * @param {Object} event The event object.
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        /**
         * Handles a click event outside the menu or on a menu item.
         * Resets the `anchorEl` state to `null`.
         */
    };

    const resultTableDispatch = useResultTableDispatch();

    return (
        <>
            <Button
                aria-describedby="showColumns"
                onClick={handleClick}
                color={"secondary"}
                endIcon={<ViewColumnIcon/>}
            >
                {t("showColumns", "Vis kolonner")}:
            </Button>
            <Popover
                id={"showColumns"}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >

                <FormGroup style={{padding: theme.spacing(2)}}>
                    {availableColumns.map(a => {
                        const label = a.getLabel(t);
                        const id = a.id;
                        const checked = a.checked;
                        return (
                            <FormControlLabel
                                key={label}
                                control={<Checkbox
                                    checked={checked}
                                    name={label}
                                    onClick={() => {
                                        resultTableDispatch({type: TOGGLE_COLUMN, id: id});
                                    }}/>}
                                label={label}
                            />);
                    })}
                </FormGroup>
            </Popover>
        </>
    );
};
