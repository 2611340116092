import React, {useCallback, useMemo, useState} from "react";
import {damsFetch} from "../app/damsFetch";
import {useDocumentState, useDocumentTranslation,} from "../documents/documentContext";
import {useAuthsState} from "../auths/authsContext";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {InputRelatedOption} from "./InputRelatedOption";
import {useField, useFormikContext} from "formik";
import {DamsModal} from "../app/DamsModal";
import {ListRelations} from "./ListRelations";
import {fetchRelationDocs} from "./FetchRelationDocs";
import throttle from "lodash/throttle";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {clientLog} from "../clientLog";

/**
 * @description
 * Component for selecting related objects.
 * @param {Object} props The props object.
 * @param {string} props.formikKey The formik key for the field.
 * @param {string} props.modelUniqueId The unique id of the current model.
 * @param {boolean} props.disabled Whether the field should be disabled. Defaults to false.
 * @param {string} props.helperText The helper text for the field. Defaults to null.
 * @param {Object} props.maxWidth The maximum width of the component. Defaults to '100%'.
 * @returns {React.ReactElement} A React component.
 */
export const InputRelatedObjects = ({
                                        formikKey,
                                        modelUniqueId,
                                        disabled = false,
                                        helperText = null,
                                        ...props
                                    }) => {
    const [field, meta] = useField(formikKey);
    const {setFieldValue} = useFormikContext();
    const [options, setOptions] = useState([]);
    const [chosenRelations, setChosenRelations] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const t = useDocumentTranslation();
    const {collectionIds} = useDocumentState();
    const {museumCollections} = useAuthsState();
    const [searching] = useState(false);
    const museums = museumCollections
        .filter((museumCollection) =>
            collectionIds.includes(museumCollection.collectionId)
        )
        .map((museumCollection) => museumCollection.museumId);

    const searchHandler = useCallback(
        (searchString) => {
            if (searching) {
                return;
            }
            setOptions([]);
            const searchParams = new URLSearchParams(
                decamelizeKeysDeep({
                    q: searchString,
                    sort: "title desc",
                    expand: true,
                    start: 0,
                    rows: 100,
                    documentType: "(StillImage OR Audio OR Video OR Misc)",
                    statsField: "created_at,updated_at,production_date",
                    museums: museums,
                    onlyMine: false,
                })
            );

            const searchCallback = json => {
                const results = json['models'].map((m) => {
                    let dmsId = null;
                    let mimeType = null;

                    const {content, collectionId, uniqueId, title, description, documentType, createdAt, imageUrl} = m;

                    if (content.mediae) {
                        dmsId = content.mediae[0].reference.sourceId;
                        mimeType = content.mediae[0].reference['mime_type'];
                    }
                    return {
                        collectionId: collectionId,
                        uniqueId: uniqueId,
                        title: title,
                        description: description,
                        documentType: documentType,
                        dmsId: dmsId,
                        mimeType: mimeType,
                        createdAt: createdAt,
                        content: content,
                        imageUrl: imageUrl || "",
                    };
                });
                const filtered = results.filter((r) => r.uniqueId !== modelUniqueId);
                setOptions(filtered);
            };

            damsFetch(`/search?${searchParams.toString()}`).then(searchCallback);
        },
        [modelUniqueId, museums, searching]
    );

    const fetchOptions = useMemo(
        () =>
            throttle((request) => {
                searchHandler(request + "*");
            }, 400),
        [searchHandler]
    );

    const onDialogClose = (data) => {
        setOpenDialog(false);
        if (data) {
            saveHandler(data);
        }
    };

    const saveHandler = (data) => {
        const referenceMaps = [];
        for (let i = 0, max = data.length; i < max; i++) {
            const r = data[i];
            const ref = {
                referenceType: "relation",
                _action: "put",
                reference: {
                    locale: "no",
                    _action: "put",
                    title: r.title,
                    status: "published",
                    source: "dams",
                    sourceId: r.sourceId,
                    content: {
                        relationType: r.relationType,
                        relationDescription: r.relationDescription,
                    },
                },
            };
            referenceMaps.push(ref);
        }
        // Merge the last chosen value, with the already selected values,
        for (let i = 0, max = referenceMaps.length; i < max; i++) {
            const rm = referenceMaps[i];
            const ix = chosenRelations.findIndex(
                (cr) => cr.uniqueId === rm.reference.sourceId
            );
            if (ix > -1) {
                chosenRelations[ix] = rm;
            }
        }
        setFieldValue(field.name, chosenRelations).then();
    };

    const changeHandler = (_event, values, reason) => {
        if ("clear" === reason) {
            setChosenRelations([]);
        } else if ("removeOption" === reason) {
            deleteExisingRelations(values);

            // Remove from options
            if (values.length === 0) {
                // If removing the last related object, the "values" array length === 0.
                setFieldValue(field.name, []).then();
            } else {
                const fieldValues = field.value;
                for (let i = 0, max = values.length; i < max; i++) {
                    const toBeDeletedId = values[i].reference.id;
                    const ix = fieldValues.findIndex(
                        (fv) => fv.reference.id !== toBeDeletedId
                    );
                    fieldValues.splice(ix, 1);
                }
                setFieldValue(field.name, fieldValues).then();
            }
        } else {
            if (values.length > 0) {
                setChosenRelations(values);
                setOpenDialog(true);
            }
        }
    };

    /**
     * Deletes existing/saved/indexed relations.
     * @param values
     */
    const deleteExisingRelations = (values) => {
        if (values && values.length > 0) {
            const docUniqueIds = values
                .filter((_) => true)
                .map((f) => f.reference.id);
            fetchRelationDocs(docUniqueIds)
                .then((res) => {
                    if (res && res.length === 0) {
                        return;
                    }

                    function getDoc(uniqueId) {
                        return res.find((f) => {
                            return f.uniqueId === uniqueId;
                        });
                    }

                    const mappedValues = values.map((v) => {
                        return {
                            title: v.reference.title,
                            sourceId: v.reference.sourceId,
                            relationType: getDoc(v.reference.uniqueId).relationType,
                            relationDescription: getDoc(v.reference.uniqueId)
                                .relationDescription,
                        };
                    });
                    saveHandler(mappedValues);
                })
                .catch((e) => {
                    clientLog('error', e, 'InputRelatedObjects');
                });
        } else {
            saveHandler([]);
        }
    };

    if (undefined === meta.initialValue) {
        return null;
    } else {
        return (
            <>
                <Autocomplete
                    sx={{
                        maxWidth: props.maxWidth
                    }}
                    id="relatedObjects"
                    disabled={disabled}
                    freeSolo
                    multiple
                    loading={Boolean(searching)}
                    loadingText={t("searchingPlaceholder", "Søker...")}
                    getOptionLabel={(option) => {
                        return option.title || option.reference.title || "";
                    }}
                    filterOptions={() => {
                        return options;
                    }}
                    filterSelectedOptions={true}
                    options={options}
                    noOptionsText={t("txtNoDocuments", "Ingen dokumenter")}
                    autoComplete={true}
                    value={field.value}
                    onChange={changeHandler}
                    onInputChange={(_event, newInputValue) => {
                        fetchOptions(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("txtNoRelatedObjects", "Relaterte objekter")}
                            helperText={helperText}
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <Box {...props} key={crypto.randomUUID()}>
                                <InputRelatedOption {...option} />
                            </Box>
                        );
                    }}
                    {...props}
                />
                <DamsModal open={openDialog}>
                    <ListRelations relations={chosenRelations} callback={onDialogClose}/>
                </DamsModal>
            </>
        );
    }
};
