import React from "react";
import {useFolderTranslation} from "./MyFoldersContext";
import {useFormikContext} from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import FolderIcon from "@mui/icons-material/Folder";
import LoadingButton from "@mui/lab/LoadingButton";

/**
 * Renders a toolbar for creating a new folder with a cancel button and a save button.
 *
 * @param {Object} props - The props object.
 * @param {Function} props.onHide - The function to be called when the cancel button is clicked.
 * @param {boolean} [props.disabled=false] - Whether the save button should be disabled.
 *
 * @return {ReactElement} The rendered toolbar component.
 */
export const ToolbarCreateFolder = ({onHide, disabled = false}) => {
    const t = useFolderTranslation();

    const {submitForm, isSubmitting, isValid, dirty} = useFormikContext();

    return (
        <Toolbar>
            <Box flexGrow={1}/>
            <Button onClick={onHide}>{t("cancel", "Avbryt")}</Button>

            <LoadingButton
                sx={{marginBottom: 2, marginTop: 2, marginLeft: 1}}
                variant={"contained"}
                color={"primary"}
                onClick={submitForm}
                loadingPosition="start"
                startIcon={<FolderIcon/>}
                loading={isSubmitting}
                disabled={isSubmitting || !isValid || disabled || !dirty}
            >
                {t("create", "Opprett")}
            </LoadingButton>
        </Toolbar>
    );
};
